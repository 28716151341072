import styled from "styled-components";

export const StyleTeamLogo = styled.img`
  height: 152px;
  width: 192px;
`;

export const StyleTeamLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
`;

export const StyleHeader = styled.p``;

export const StyleHeaderContainer = styled.div`
  font-family: futura-pt, "sans-serif";
  font-weight: 700;
  display: flex;
  justify-content: center;
  ${(props) => props.StyleHeaderContainer || ""};
`;

const StyleContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  width: 100%;
  align-items: center;
  font-family: futura-pt, "sans-serif";
`;

export const StyleWhoWillWinContent = styled(StyleContainer)`
  ${(props) =>
    props.StyleWhoWillWinContent ? props.StyleWhoWillWinContent : ""};
`;

export const StyleWhoWillWinHeader = styled(StyleContainer)``;

export const StyleHashtag = styled.p`
  font-size: 30px;
  font-weight: 400;
`;

export const StylePickemLogo = styled.img`
  height: 30px;
  padding-right: 15px;
`;
