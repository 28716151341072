"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TeamVideoLogo = exports.TeamScore = exports.TeamName = exports.TeamLogoContainer = exports.TeamLogo = exports.TeamInfo = exports.TeamContainer = exports.StyleChampionsStatsContainer = exports.ScoreBox = exports.Score = exports.PhasesContainer = exports.Phase = exports.Patch = exports.Header = exports.Container = exports.CoachContainer = exports.Base = exports.BanPickLogo = void 0;

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _styledTools = require("styled-tools");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

const Base = _styledComponents.default.div.withConfig({
  displayName: "styles__Base",
  componentId: "sc-90ro2b-0"
})(["height:1080px;width:1920px;font-family:inherit;font-weight:700;background-image:", ";"], props => "url(".concat(props.backgroundImage, ")"));

exports.Base = Base;

const StyleChampionsStatsContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleChampionsStatsContainer",
  componentId: "sc-90ro2b-1"
})(["width:100%;height:100%;position:absolute;top:0;left:0;z-index:10;"]);

exports.StyleChampionsStatsContainer = StyleChampionsStatsContainer;

const Container = _styledComponents.default.div.withConfig({
  displayName: "styles__Container",
  componentId: "sc-90ro2b-2"
})(["position:relative;top:0;left:0;font-family:inherit;"]);

exports.Container = Container;

const Header = _styledComponents.default.div.withConfig({
  displayName: "styles__Header",
  componentId: "sc-90ro2b-3"
})(["background-color:transparent;height:196px;position:relative;width:100%;"]);

exports.Header = Header;

const TeamContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__TeamContainer",
  componentId: "sc-90ro2b-4"
})(["position:absolute;top:0;"]); // ${switchProp("align", {
//     "left": css`
//         clip-path: polygon(0 0, 93% 0, 100% 100%, 0% 100%);
//         height: 100%;
//         left: 0;
//     `,
//     "right": css`
//         clip-path: polygon(7% 0, 100% 0, 100% 100%, 0% 100%);
//         left: unset;
//         right: 0;
//     `,
// })}


exports.TeamContainer = TeamContainer;

const TeamLogo = _styledComponents.default.div.withConfig({
  displayName: "styles__TeamLogo",
  componentId: "sc-90ro2b-5"
})(["position:relative;.logo-left{position:absolute;top:5px;left:35px;height:150px;}.logo-right{position:absolute;top:5px;right:35px;height:150px;}"]);

exports.TeamLogo = TeamLogo;

const TeamName = _styledComponents.default.div.withConfig({
  displayName: "styles__TeamName",
  componentId: "sc-90ro2b-6"
})(["color:", ";font-size:75px;position:absolute;text-shadow:0px 6px 6px black;z-index:2;top:", ";", " ", ""], props => props.textColor, props => props.isPlayoffs ? "40px" : "28px", (0, _styledTools.switchProp)("align", {
  left: (0, _styledComponents.css)(["left:1326px;text-align:start;"]),
  right: (0, _styledComponents.css)(["right:1326px;text-align:end;"])
}), props => props.css);

exports.TeamName = TeamName;

const TeamScore = _styledComponents.default.div.withConfig({
  displayName: "styles__TeamScore",
  componentId: "sc-90ro2b-7"
})(["color:white;font-size:25px;position:absolute;top:110px;letter-spacing:0.25px;z-index:1;", " ", ""], (0, _styledTools.switchProp)("align", {
  left: (0, _styledComponents.css)(["right:570px;"]),
  right: (0, _styledComponents.css)(["left:570px;"])
}), props => props.cssTeamReccord || "");

exports.TeamScore = TeamScore;

const TeamInfo = _styledComponents.default.div.withConfig({
  displayName: "styles__TeamInfo",
  componentId: "sc-90ro2b-8"
})(["height:162px;width:100%;top:0;overflow:hidden;position:relative;", " ", ""], (0, _styledTools.switchProp)("align", {
  left: (0, _styledComponents.css)(["left:0;"]),
  right: (0, _styledComponents.css)(["right:0;"])
}), props => props.css);

exports.TeamInfo = TeamInfo;

const TeamVideoLogo = _styledComponents.default.video.withConfig({
  displayName: "styles__TeamVideoLogo",
  componentId: "sc-90ro2b-9"
})(["overflow:hidden;width:100%;"]);

exports.TeamVideoLogo = TeamVideoLogo;

const TeamLogoContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__TeamLogoContainer",
  componentId: "sc-90ro2b-10"
})(["height:100%;width:100%;position:absolute;background-image:", ";background-position-x:", ";background-blend-mode:multiply;top:0;"], props => "linear-gradient(to ".concat(props.direction, ", ").concat(props.color, "e5, ").concat(props.color, "00)"), props => props.direction === "left" ? "-690px" : "-700px");

exports.TeamLogoContainer = TeamLogoContainer;

const CoachContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__CoachContainer",
  componentId: "sc-90ro2b-11"
})(["bottom:0;color:", ";font-size:30px;height:32px;text-transform:uppercase;font-weight:500;margin-top:6px;", " ", ""], props => props.textColor, (0, _styledTools.switchProp)("align", {
  left: (0, _styledComponents.css)(["bottom:0;color:white;font-size:30px;left:0;padding-left:20px;text-align:left;width:100%;background-image:", ";display:flex;align-items:center;justify-content:flex-start;"], props => "linear-gradient(to right, ".concat(props.backgroundColorCoach, "ff, ").concat(props.backgroundColorCoach, "00)")),
  right: (0, _styledComponents.css)(["left:unset;padding-left:0;padding-right:20px;right:0;text-align:right;width:100%;background-image:", ";display:flex;align-items:center;justify-content:flex-end;"], props => "linear-gradient(to left, ".concat(props.backgroundColorCoach, "ff, ").concat(props.backgroundColorCoach, "00)"))
}), props => props.css);

exports.CoachContainer = CoachContainer;

const Score = _styledComponents.default.div.withConfig({
  displayName: "styles__Score",
  componentId: "sc-90ro2b-12"
})(["align-items:center;color:", ";display:flex;font-size:75px;font-weight:700;justify-content:center;left:50%;position:absolute;top:0;transform:translateX(-50%);"], props => props.textColor);

exports.Score = Score;

const ScoreBox = _styledComponents.default.div.withConfig({
  displayName: "styles__ScoreBox",
  componentId: "sc-90ro2b-13"
})(["font-size:55px;margin:0 10px;", ""], props => props.css);

exports.ScoreBox = ScoreBox;

const Patch = _styledComponents.default.div.withConfig({
  displayName: "styles__Patch",
  componentId: "sc-90ro2b-14"
})(["bottom:5px;color:", ";font-size:24px;left:50%;top:46%;position:absolute;text-transform:uppercase;transform:translateX(-50%);", ""], props => props.textColor, props => props.css);

exports.Patch = Patch;

const BanPickLogo = _styledComponents.default.div.withConfig({
  displayName: "styles__BanPickLogo",
  componentId: "sc-90ro2b-15"
})(["background-image:", ";background-position:center;background-repeat:no-repeat;background-size:contain;bottom:50px;height:130px;left:50%;position:absolute;transform:translate(-50%,885px);width:130px;", ""], props => "url(".concat(props.image, ")"), props => props.cssLeagueLogo || "");

exports.BanPickLogo = BanPickLogo;

const PhasesContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__PhasesContainer",
  componentId: "sc-90ro2b-16"
})(["position:absolute;bottom:0;color:", ";left:", ";right:", ";margin-right:", ";margin-left:", ";transform:translateY(705px);display:flex;flex-direction:row;"], props => props.textColor, props => props.position === "left" ? "0" : "unset", props => props.position === "right" ? "0" : "unset", props => props.position === "right" ? "45px" : "0px", props => props.position === "left" ? "50px" : "0px");

exports.PhasesContainer = PhasesContainer;

const Phase = _styledComponents.default.div.withConfig({
  displayName: "styles__Phase",
  componentId: "sc-90ro2b-17"
})(["font-size:30px;margin-right:", ";margin-left:", ";"], props => props.position === "right" ? "360px" : "0px", props => props.position === "left" ? "355px" : "0px");

exports.Phase = Phase;