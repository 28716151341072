import moment from "moment";
import { previewGameInfo } from "../lib/allstats-sdk/api";
import { i18n } from "../components/i18n";
import champList from "./championService";

const getPreviewGameInfo = (platform, gameID, getRawResponse) => {
  return previewGameInfo(platform, gameID).then((res) => {
    if (res.error) {
      throw res.error;
    }
    // FROM OLD LJL ANALYTICS
    // if (!res.gameIsComplete) {
    //   throw i18n().error.gameNotComplete;
    // }
    // if (!res.gameData || !res.timeline) {
    //   throw i18n().error.gameNotFound;
    // }

    // FROM NEW 2025 RIOT API
    if (res.json.endOfGameResult !== "GameComplete") {
      throw i18n().error.gameNotComplete;
    }
    if (!res.json) {
      throw i18n().error.gameNotFound;
    }
    if (!champList) {
      throw i18n().error.noChampList;
    }

    // FROM OLD LJL ANALYTICS
    // if (
    //   !res.gameData.gameInfo ||
    //   !res.gameData.gameInfo.bannedChampions ||
    //   !res.gameData.participants ||
    //   !res.gameData.gameStats ||
    //   !res.gameData.gameStats.teamStats
    // ) {
    //   throw i18n().error.dataMalformed;
    // }

    // FROM NEW 2025 RIOT API
    if (
      !res.json.gameId ||
      !res.json.platformId ||
      !res.json.teams ||
      !res.json.participants
    ) {
      throw i18n().error.dataMalformed;
    }

    if (getRawResponse) return res;

    // FROM OLD LJL ANALYTICS
    // const bans = res.gameData.gameInfo.bannedChampions.map(
    //   (p) => champList[p.championId] && champList[p.championId].id
    // );
    // const picks = res.gameData.participants.map(
    //   (p) => champList[p.championId] && champList[p.championId].id
    // );

    // FROM NEW 2025 RIOT API
    const bans = res.json.teams.flatMap((team) =>
      team.bans.map((ban) => champList[ban.championId]?.id)
    );
    const picks = res.json.participants.map(
      (p) => champList[p.championId] && champList[p.championId].id
    );

    const summonerNames = res.json.participants.map((p) => p.riotIdGameName);

    // FROM OLD LJL ANALYTICS
    // const winner =
    //   res.gameData.gameStats.teamStats[0].win === "Win" ? "team100" : "team200";
    // const date = moment(res.gameData.gameInfo.gameCreationTime);

    // FROM NEW 2025 RIOT API
    const winner = res.json.teams[0].win === true ? "team100" : "team200";
    const date = moment(res.json.gameCreation);

    const gameCreatedAt = `${date.format(
      "YYYY/MM/DD hh:mm:ss"
    )} (${date.fromNow()})`;
    const previewData = {
      picks,
      bans,
      summonerNames,
      winner,
      gameCreatedAt,
    };
    return previewData;
  });
};

export default getPreviewGameInfo;
