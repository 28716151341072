/* eslint-disable no-unused-vars */
// pass in the path to the desired file beneath the images folder
// eg. for accessing the file at "/src/images/results-logos/7h-results-logo.png"
// ______ pass in loadImageUrl('/results-logos/7h-results-logo.png')

import runeDefs from "./runesReforged.json";

const loadImageUrl = (path) => path || null;

const normalizeName = (name) => {
  return name ? name.replace(" ", "").toLowerCase() : null;
};

// specific asset requests
const loadImageUrlChampSquare = (champ) => {
  const url = `https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/${champ}.png`;
  return loadImageUrl(url);
};

const loadImageUrlPosition = (position) => {
  const url = `https://cdn.dekki.com/assets/broadcast/games/league-of-legends/positions/${position}.svg`;
  return loadImageUrl(url);
};

const loadImageUrlChampCentered = (champ) => {
  const url = `https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/${normalizeName(
    champ
  )}.jpg`;
  return loadImageUrl(url);
};

const getPlayerImageUrl = (player, teamData, imageType) => {
  let defaultImage = "";
  switch (imageType) {
    case "matchup":
      defaultImage =
        "https://cdn.dekki.com/uploads/players/_default/profilesquare.png";
      break;
    case "voting":
      defaultImage =
        "https://cdn.dekki.com/uploads/players/_default/fullbody.png";
      break;
    default:
      defaultImage =
        "https://cdn.dekki.com/uploads/players/_default/fullbody.png";
      break;
  }

  const roster = teamData.roster;
  if (!roster) {
    return defaultImage;
  } else {
    const lowercaseRoster = {};
    Object.keys(roster).forEach((key) => {
      lowercaseRoster[key.toLowerCase()] = roster[key];
    });
    const p = lowercaseRoster[player.toLowerCase()];
    if (!p) return defaultImage;
    return (p.images && p.images[imageType]) || defaultImage;
  }
};

const getTeamImageUrl = (teamData, imageType) => {
  return teamData.images && teamData.images[imageType];
};

const loadImageUrlPlayerMatchup = (player, team, league, season, split) => {
  return loadImageUrl(getPlayerImageUrl(player, team, "matchup"));
};

const loadImageUrlPlayerResults = (player, team, league, season, split) => {
  return loadImageUrl(getPlayerImageUrl(player, team, "results"));
};

const loadImageUrlPlayerResultsGlow = (player, team, league, season, split) => {
  return loadImageUrl(
    player ? getPlayerImageUrl(player, team, "resultsGlow") : ""
  );
};

const loadImageUrlPlayerStarters = (player, team, league, season, split) => {
  return loadImageUrl(
    player ? getPlayerImageUrl(player, team, "starters") : ""
  );
};

const loadImageUrlPlayerVoting = (player, team, league, season, split) => {
  return loadImageUrl(getPlayerImageUrl(player, team, "voting"));
};

const loadImageUrlTeamLogo = (team, league, season, split) => {
  return loadImageUrl(getTeamImageUrl(team, "logoFullColor"));
};

const loadImageUrlTeamColorLogo = (team, league, season, split) => {
  //   return loadImageUrl(getTeamImageUrl(team, "logoTeamColor"));
  return loadImageUrl(getTeamImageUrl(team, "logoWhite"));
};

const loadImageUrlTeamScoreLogo = (team, league, season, split) => {
  return loadImageUrl(getTeamImageUrl(team, "logoGameScreen"));
};

const loadImageUrlTeamTag = (team, league, season, split) => {
  const url = `/leagues/${league}/${season}/${split}/teams/${normalizeName(
    team
  )}/assets/tag_brush-gradient_${team.toLowerCase()}.png`;
  return loadImageUrl(url);
};

let runeImages = null;
const getRuneImageUrl = (runeID) => {
  if (!runeImages) {
    const runeImagesData = {};

    runeDefs.forEach((rune) => {
      runeImagesData[
        rune.id
      ] = `https://cdn.dekki.com/meta/games/league-of-legends/${rune.icon}`;
      rune.slots.forEach((slot) => {
        slot.runes.forEach((slotRune) => {
          runeImagesData[
            slotRune.id
          ] = `https://cdn.dekki.com/meta/games/league-of-legends/${slotRune.icon}`;
        });
      });
    });
    runeImages = runeImagesData;
  }
  return runeImages[runeID];
};

const preloadImage = async (url) =>
  await new Promise((resolve) => {
    if (!url) {
      resolve();
      return;
    }
    const img = new Image();
    img.onload = function () {
      resolve();
    };
    img.onerror = function () {
      resolve();
    };
    img.src = url;
  });

const preloadImages = async (urls) => {
  const promises = [];
  urls.forEach((url) => {
    if (url) {
      promises.push(preloadImage(url));
    }
  });
  if (promises.length > 0) {
    await Promise.all(promises);
  }
};

function imageError(ev) {
  ev.target.src =
    "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/dummy_ban.png";
}

export {
  loadImageUrl,
  loadImageUrlChampSquare,
  loadImageUrlChampCentered,
  loadImageUrlPlayerMatchup,
  loadImageUrlPlayerResults,
  loadImageUrlPlayerResultsGlow,
  loadImageUrlPlayerStarters,
  loadImageUrlPlayerVoting,
  loadImageUrlPosition,
  loadImageUrlTeamLogo,
  loadImageUrlTeamColorLogo,
  loadImageUrlTeamScoreLogo,
  loadImageUrlTeamTag,
  normalizeName,
  preloadImage,
  preloadImages,
  getRuneImageUrl,
  imageError,
};
