"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyleTurretsStatus = exports.StyleTurretStatus = exports.StyleTurretImg = exports.StyleTeamContainer = exports.StyleStatusGoldPerTeam = exports.StyleInfoBoxBody = exports.StyleDamagePlayer = exports.StyleDamagePerPlayers = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const StyleInfoBoxBody = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleInfoBoxBody",
  componentId: "sc-1v3t1f1-0"
})(["color:white;display:grid;grid-template-columns:repeat(2,1fr);align-items:center;height:210px;position:absolute;width:1340px;overflow:visible;top:70px;bottom:0;", ";"], props => props.StyleInfoBoxBody ? props.StyleInfoBoxBody : "");

exports.StyleInfoBoxBody = StyleInfoBoxBody;

const StyleTeamContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleTeamContainer",
  componentId: "sc-1v3t1f1-1"
})(["display:grid;grid-template-columns:", ";height:210px;justify-items:center;border-right:1px solid #ffffff80;:last-of-type{border-right:unset;}", ";"], props => props.side === "left" ? "2fr 1fr" : "1fr 2fr", props => props.StyleTeamContainer ? props.StyleTeamContainer : "");

exports.StyleTeamContainer = StyleTeamContainer;

const StyleTurretImg = _styledComponents.default.img.withConfig({
  displayName: "styles__StyleTurretImg",
  componentId: "sc-1v3t1f1-2"
})(["transition:opacity 300ms ease-in-out;transition-delay:100ms;opacity:", ";", " ", ";"], props => props.show ? 1 : 0, props => props.color === "blue" ? "   transform: translate3D(25px, -138px, 0);\n            width: 225px;\n        " : "   transform: translate3D(0, -200px, 0);\n            width: 210px;\n        ", props => props.StyleTurretImg ? props.StyleTurretImg : "");

exports.StyleTurretImg = StyleTurretImg;

const StyleTurretsStatus = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleTurretsStatus",
  componentId: "sc-1v3t1f1-3"
})(["display:grid;grid-template-columns:repeat(3,1fr);width:100%;justify-items:center;padding:0px 100px;height:auto;align-content:center;", ";"], props => props.StyleTurretsStatus ? props.StyleTurretsStatus : "");

exports.StyleTurretsStatus = StyleTurretsStatus;

const StyleTurretStatus = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleTurretStatus",
  componentId: "sc-1v3t1f1-4"
})(["width:100%;height:100px;text-align:center;align-self:center;img{width:80px;height:80px;margin:-10px;}p{text-align:center;line-height:50px;color:#f5f5f5;font-size:32px;}", ";"], props => props.StyleTurretStatus ? props.StyleTurretStatus : "");

exports.StyleTurretStatus = StyleTurretStatus;

const StyleStatusGoldPerTeam = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleStatusGoldPerTeam",
  componentId: "sc-1v3t1f1-5"
})(["display:flex;grid-column-start:1;grid-column-end:4;text-align:center;font-size:46px;font-family:\"futura-pt\",sans-serif;font-weight:700;color:", ";overflow:hidden;transition:height 300ms linear;height:auto;", ";"], props => props.color || "white", props => props.StyleStatusGoldPerTeam ? props.StyleStatusGoldPerTeam : "");

exports.StyleStatusGoldPerTeam = StyleStatusGoldPerTeam;

const StyleDamagePerPlayers = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleDamagePerPlayers",
  componentId: "sc-1v3t1f1-6"
})(["display:grid;height:210px;padding:10px 0;width:100%;margin-top:10px;", ";"], props => props.StyleDamagePerPlayers ? props.StyleDamagePerPlayers : "");

exports.StyleDamagePerPlayers = StyleDamagePerPlayers;

const StyleDamagePlayer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleDamagePlayer",
  componentId: "sc-1v3t1f1-7"
})(["display:flex;justify-content:space-evenly;img{width:28px;height:28px;}p{min-width:90px;font-size:25px;font-family:\"futura-pt\",sans-serif;font-weight:600;color:", ";text-align:", ";}", ";"], props => props.color || "white", props => props.side === "left" ? "left" : "right", props => props.StyleDamagePlayer ? props.StyleDamagePlayer : "");

exports.StyleDamagePlayer = StyleDamagePlayer;