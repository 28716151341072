// REACT
import React, { Component } from "react";
// INTERNAL
import { /* send, */ subscribe } from "../../../services/api";
import ControlContainer from "../../Controller/ControlContainer";
import Button from "../../Button";
import validateDataGameBreakdown from "../../Data/DataGameBreakdown/validate";
import { i18n } from "../../i18n";
import {
  loadImageUrlTeamColorLogo,
  loadImageUrlChampSquare,
} from "../../../util/loadImageUrl";
import { sendPlayersLoopLayoutWidgetData } from "../../../util/widgetUtil";
import { updateScreenStatus } from "../../../util/controllerUtils";
import PropTypes from "prop-types";
import { StyleSelect } from "./styles";

class GameBreakdownControl extends Component {
  static propTypes = {
    cgpc: PropTypes.string,
    display: PropTypes.bool,
    screen1: PropTypes.bool,
  };
  state = {
    status: "ready",
    breakdownMode: "new",
  };

  componentDidMount() {
    this.runSubscription();
    if (validateDataGameBreakdown()) {
      this.setState({ status: "ready" });
    } else {
      this.setState({ status: "invalid" });
    }
  }

  runSubscription = () => {
    subscribe(
      "control-gameBreakdown",
      null,
      (data) => {
        if (data.target === "control-gameBreakdown") {
          const resultState = {
            ...data,
            ...data.data,
          };
          delete resultState.data;
          this.setState(resultState);
        }
      },
      this.props.cgpc
    );
  };

  dummyTeam = {
    name: "Dummy Team",
    tricode: "dum",
    color: {
      main: "#ab8652",
      sub: "#593d25",
    },
    coach: "Dummy Coach",
    supertext: "XXX",
    roster: {},
    images: {
      postgameLeft:
        "https://cdn.dekki.com/uploads/teams/dummy/postgame_dummy_left.png",
      postgameRight:
        "https://cdn.dekki.com/uploads/teams/dummy/postgame_dummy_right.png",
    },
  };

  onPlay = () => {
    if (validateDataGameBreakdown()) {
      const schedule = window.MainData.schedule;
      const teams = window.MainData.teams;
      const theme = schedule.league;

      const roundId = schedule.round;
      const matchId = schedule.match;
      const gameId = schedule.game;
      const match = schedule.rounds[roundId].matches[matchId];
      const flipped =
        match.games[gameId].teams[match.team100.tricode].side !== "100";
      const gameTeam100score = flipped
        ? match.team200.score
        : match.team100.score;
      const gameTeam200score = flipped
        ? match.team100.score
        : match.team200.score;

      const team100 =
        teams[window.MainData.gameBreakdown.teams.team100Name.toUpperCase()] ||
        this.dummyTeam;
      const team200 =
        teams[window.MainData.gameBreakdown.teams.team200Name.toUpperCase()] ||
        this.dummyTeam;

      const team100StatsBackgroundImage =
        team100 &&
        loadImageUrlTeamColorLogo(
          team100,
          schedule.league,
          schedule.season,
          schedule.split
        );
      const team200StatsBackgroundImage =
        team200 &&
        loadImageUrlTeamColorLogo(
          team200,
          schedule.league,
          schedule.season,
          schedule.split
        );

      const sendData = {
        breakdownMode: this.state.breakdownMode,
        team100,
        team200,
        theme,
        gameBreakdown: window.MainData.gameBreakdown,
        team100StatsBackgroundImage,
        team200StatsBackgroundImage,
        gameTeamLeftScore: match.bestOf > 1 ? gameTeam100score || 0 : null,
        gameTeamRightScore: match.bestOf > 1 ? gameTeam200score || 0 : null,
        bestOf: match.bestOf,
        preloadImages: [
          team100StatsBackgroundImage,
          team200StatsBackgroundImage,
        ]
          .concat(
            window.MainData.gameBreakdown.picks.map((pick) =>
              loadImageUrlChampSquare(pick)
            )
          )
          .concat(
            window.MainData.gameBreakdown.bans.map((ban) =>
              loadImageUrlChampSquare(ban)
            )
          )
          .filter((url) => url),
      };

      this.setState({ status: "ready" });
      console.log(sendData);
      sendPlayersLoopLayoutWidgetData().then(() => {
        // send('event', { target: 'view', action: 'open', screen: 'gameBreakdown', data: sendData });
        updateScreenStatus("gameBreakdown", "open", sendData);
      });
    } else {
      this.setState({ status: "invalid" });
    }
  };

  onClose = () => {
    // send('event', { target: 'view', action: 'close', screen: 'gameBreakdown' });
    updateScreenStatus("gameBreakdown", "ready", null);
  };

  onChangeBreakdownMode = (event) => {
    this.setState({ breakdownMode: event.target.value });
  };

  render() {
    return (
      <ControlContainer
        style={{ display: this.props.display ? "none" : "block" }}
        className="gameBreakdown--matchup"
        status={this.state.status}
      >
        <div className="control__panel">
          <h2 className="control__title">
            <i className="fas fa-chart-line"></i> {i18n().pages.gameBreakdown}
          </h2>
          <Button
            controllerSide={!this.props.screen1}
            action={this.onPlay}
            text={i18n().general.open}
            icon="fas fa-play-circle"
            disabled={
              (this.state.status === "open" ||
                this.state.status === "opening") &&
              true
            }
          />
          <Button
            controllerSide={!this.props.screen1}
            action={this.onClose}
            text={i18n().general.close}
            icon="fas fa-stop-circle"
            disabled={
              !(
                this.state.status === "open" || this.state.status === "opening"
              ) && true
            }
          />
        </div>
        <div className="control-extra">
          <div className="control-extra__fieldset">
            <label className="control-extra__fieldset__label">
              {i18n().general.mode}
            </label>
            <StyleSelect
              onChange={this.onChangeBreakdownMode}
              value={this.state.breakdownMode}
            >
              <option value="old">{i18n().general.old}</option>
              <option value="new">{i18n().general.new}</option>
            </StyleSelect>
          </div>
        </div>
      </ControlContainer>
    );
  }
}

export default GameBreakdownControl;
