// React
import React, { Component } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "../View/themes";
// LIBRARIES
import { delay } from "lodash";
// ME
import BanPickScreen from "../../storybook-built/dist/components/Games/LJL/BanPick";
// import { preloadImages } from '../../util/loadImageUrl';

class BanPick extends Component {
  static propTypes = {
    // active: PropTypes.bool,
    action: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
    data: PropTypes.any,
    statusCallback: PropTypes.func,
  };

  state = {
    status: "closed",
  };

  componentDidMount() {
    delay(() => this.doAction(this.props), 100);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.doAction(nextProps);
  }

  doAction = (props) => {
    if (props.active) {
      if (this.state.status !== "open") {
        this.props.statusCallback("banPick", "open");
        this.setState({ status: "open" });
      }
    } else {
      if (this.state.status === "open") {
        this.props.statusCallback("banPick", "closed");
        this.setState({ status: "closed" });
      }
    }
  };

  render() {
    const theme = this.context;
    const DayName =
      this?.props?.data?.week?.split("DAY ")?.length > 1 &&
      theme?.id?.includes("academy")
        ? `D${this.props.data.week.split("DAY ").pop()}-G${
            this.props.data.match
          }`
        : theme.screens.banPick.titleDisplay === "week" ||
          !theme.screens.banPick.titleDisplay
        ? this.props.data.week
        : theme.screens.banPick.titleDisplay.toUpperCase() +
          " " +
          this.props.data.match;
    return this.props.data.teamsBanPick &&
      this.props.data.teamsBanPick.length > 0 ? (
      <div className={"container"}>
        <BanPickScreen
          isShowing={true}
          teams={this.props.data.teamsBanPick}
          bestOf={this.props.data.bestOf}
          week={DayName}
          matchRecords={this.props.data.wonMatches}
          championsStats={this.props.data.championsStats}
          patch={this.props.data.patch ? `PATCH ${this.props.data.patch}` : ""}
          i18n={{
            pickPhase: "ピック-フェーズ",
          }}
          theme={theme.screens.banPick || {}}
        />
      </div>
    ) : (
      <div></div>
    );
  }
}

BanPick.contextType = ThemeContext;

export default BanPick;
