// REACT
import React, { Component } from "react";
import { get } from "lodash";
import PropTypes from "prop-types";

// INTERNAL
import { subscribe } from "../../../services/api";
import ControlContainer from "../../Controller/ControlContainer";
import Button from "../../Button";
import validateDataSchedule from "../../Data/DataSchedule/validate";
import { i18n } from "../../i18n";
import {
  loadImageUrlPlayerMatchup,
  loadImageUrlChampCentered,
} from "../../../util/loadImageUrl";
import champions from "../../../services/championService";
import { updateScreenStatus } from "../../../util/controllerUtils";

class MatchupV2Control extends Component {
  state = {
    status: "ready",
    // statusDetail: '',
    photosAvailable: true,
  };

  static propTypes = {
    cgpc: PropTypes.string,
    display: PropTypes.bool,
    screen1: PropTypes.bool,
  };

  componentDidMount() {
    this.runSubscription();
    if (validateDataSchedule()) {
      this.setState({ status: "ready" });
    } else {
      this.setState({ status: "invalid" });
    }
    if (window.MainData.theme.includes("academy")) {
      this.setState({ photosAvailable: false });
    }
  }

  runSubscription = () => {
    subscribe(
      "control-matchupV2",
      null,
      (data) => {
        if (data.target === "control-matchupV2") {
          const resultState = {
            ...data,
            ...data.data,
          };
          delete resultState.data;
          this.setState(resultState);
        }
      },
      this.props.cgpc
    );
  };

  onPlay = () => {
    // check data
    // if (this.checkData()) {
    this.setState({ status: "open" });
    updateScreenStatus(
      "matchupV2",
      "open",
      this.getSendData(),
      null,
      this.props.cgpc
    );
    // } else {
    //     this.setState({status: 'invalid'});
    // }
  };

  onClose = () => {
    // send('event', { target: 'view', action: 'close', screen: 'matchup' });
    this.setState({ status: "close" });
    updateScreenStatus("matchupV2", "close", null, null, this.props.cgpc);
  };

  checkData = () => {
    const data = this.getSendData();
    return (
      !Object.values(data.teamLeftRoster).filter(
        (position) =>
          !position.imageUrlChampCentered || !position.imageUrlPlayerMatchup
      ).length &&
      !Object.values(data.teamRightRoster).filter(
        (position) =>
          !position.imageUrlChampCentered || !position.imageUrlPlayerMatchup
      ).length
    );
  };

  getSendData = (toUpdate, value) => {
    const schedule = window.MainData.schedule;
    const league = schedule.league;
    const season = schedule.season;
    const split = schedule.split;
    const match = schedule.rounds[schedule.round].matches[schedule.match];
    const game = match.games[schedule.game];
    const theme = schedule.league;
    const flipped =
      game.teams[match.team100.tricode].side === "100" ? false : true;
    const team100 = flipped
      ? window.MainData.teams[match.team200.tricode]
      : window.MainData.teams[match.team100.tricode];
    const team200 = flipped
      ? window.MainData.teams[match.team100.tricode]
      : window.MainData.teams[match.team200.tricode];

    const bans100 = Object.keys(
      game.teams[team100.tricode.toUpperCase()].bans
    ).map((ban) => game.teams[team100.tricode.toUpperCase()].bans[ban]);
    const bans200 = Object.keys(
      game.teams[team200.tricode.toUpperCase()].bans
    ).map((ban) => game.teams[team200.tricode.toUpperCase()].bans[ban]);

    const gameTeam100Tricode = team100.tricode;
    const gameTeam200Tricode = team200.tricode;
    const gameTeam100Color = team100.color.main;
    const gameTeam200Color = team200.color.main;
    const gameTeam100SubColor = team100.color.sub;
    const gameTeam200SubColor = team200.color.sub;
    const gameTeam100Name = team100.name;
    const gameTeam200Name = team200.name;
    const gameTeam100Logo = team100.images.logoWhite;
    const gameTeam200Logo = team200.images.logoWhite;

    const team100Roster = {};
    const team200Roster = {};
    Object.keys(game.teams[team100.tricode.toUpperCase()].roster).forEach(
      (key) => {
        const item = game.teams[team100.tricode.toUpperCase()].roster[key];
        const champName = item.championId ? champions[item.championId].id : "";
        if (typeof item !== "string") {
          item.championName = champName;
          item.imageUrlChampCentered =
            champName && loadImageUrlChampCentered(champName);
          item.imageUrlPlayerMatchup = loadImageUrlPlayerMatchup(
            item.name,
            team100,
            league,
            season,
            split
          );
          item.realname = get(team100, `roster[${item.playerId}].name`, "");
          team100Roster[key] = item;
        }
      }
    );
    Object.keys(game.teams[team200.tricode.toUpperCase()].roster).forEach(
      (key) => {
        const item = game.teams[team200.tricode.toUpperCase()].roster[key];
        const champName = item.championId ? champions[item.championId].id : "";
        if (typeof item !== "string") {
          item.championName = champName;
          item.imageUrlChampCentered =
            champName && loadImageUrlChampCentered(champName);
          item.imageUrlPlayerMatchup = loadImageUrlPlayerMatchup(
            item.name,
            team200,
            league,
            season,
            split
          );
          item.realname = get(team200, `roster[${item.playerId}].name`, "");
          team200Roster[key] = item;
        }
      }
    );

    return {
      teamLeftTricode: gameTeam100Tricode,
      teamRightTricode: gameTeam200Tricode,
      teamLeftMainColor: gameTeam100Color,
      teamRightMainColor: gameTeam200Color,
      teamLeftSubColor: gameTeam100SubColor,
      teamRightSubColor: gameTeam200SubColor,
      teamLeftName: gameTeam100Name,
      teamRightName: gameTeam200Name,
      teamLeftLogo: gameTeam100Logo,
      teamRightLogo: gameTeam200Logo,
      teamLeftBans: bans100,
      teamRightBans: bans200,
      teamLeftRoster: team100Roster,
      teamRightRoster: team200Roster,
      theme,
      update: toUpdate,
      value,
      photosAvailable: this.state.photosAvailable,
    };
  };

  onUpdate = (toUpdate, v) => {
    this.setState({ [toUpdate]: v }, () => {
      if (this.state.status === "open") {
        updateScreenStatus(
          "matchupV2",
          "open",
          this.getSendData(),
          null,
          this.props.cgpc
        );
      }
    });
  };

  render() {
    return (
      <ControlContainer
        style={{ display: this.props.display ? "none" : "block" }}
        className="control--matchup"
        status={this.state.status}
      >
        <div className="control__panel">
          <h2 className="control__title">
            <i className="fas fa-child"></i> {i18n().pages.matchupV2}
          </h2>
          <Button
            controllerSide={!this.props.screen1}
            action={this.onPlay}
            text={i18n().general.open}
            icon="fas fa-play-circle"
            disabled={
              (this.state.status === "open" ||
                this.state.status === "opening") &&
              true
            }
          />
          <Button
            controllerSide={!this.props.screen1}
            action={this.onClose}
            text={i18n().general.close}
            icon="fas fa-stop-circle"
            disabled={
              !(
                this.state.status === "open" || this.state.status === "opening"
              ) && true
            }
          />
        </div>
        <div className="control-extra">
          <div className="control-extra__fieldset">
            <label className="control-extra__fieldset__label">
              {i18n().general.DisplayPhotos}
            </label>
            <div className="pill">
              {this.state.photosAvailable
                ? [
                    <span key="1a" className="pill__span--highlighted">
                      {i18n().controller.viewable.true}
                    </span>,
                    <Button
                      controllerSide={!this.props.screen1}
                      key="1b"
                      disabled={this.state.status === "open"}
                      text={i18n().controller.viewable.false}
                      action={() => this.onUpdate("photosAvailable", false)}
                    />,
                  ]
                : [
                    <Button
                      controllerSide={!this.props.screen1}
                      key="2b"
                      disabled={this.state.status === "open"}
                      text={i18n().controller.viewable.true}
                      action={() => this.onUpdate("photosAvailable", true)}
                    />,
                    <span key="2a" className="pill__span--highlighted">
                      {i18n().controller.viewable.false}
                    </span>,
                  ]}
            </div>
          </div>
        </div>
      </ControlContainer>
    );
  }
}

export default MatchupV2Control;
