import styled from "styled-components";
import { switchProp } from "styled-tools";

export const SponsorContainerElement = styled.div`
  display: flex;
  justify-content: center;
  font-size: 50px;
  width: 280px;
  height: 102px;
  text-align: center;
  color: #795b18;
  font-weight: 700;
  align-items: center;
  ${(props) =>
    props.SponsorContainerElement ? props.SponsorContainerElement : ""};
`;

export const Asset = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 99;
  transition: opacity 1s linear;
  opacity: ${(props) => (props.show ? 1 : 0)};
`;

export const BGAsset = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
`;

export const BuffBaronGoldDiff = styled.div`
  position: absolute;
  font-weight: 500;
  font-family: "futura-pt", "sans-serif";
  color: white;
  font-size: 15px;
  width: 153px;
  padding: 2px 0;
  right: 33px;
  top: 85px;
  transition: height 0.5s ease-in;
  height: ${(props) => (props.show ? "20" : "0")}px;
  text-align: center;
  ${(props) => (props.BuffBaronGoldDiff ? props.BuffBaronGoldDiff : "")};
`;

export const BuffBaronTimer = styled.div`
  position: absolute;
  height: 35px;
  font-size: 28px;
  font-weight: 500;
  font-family: "futura-pt", "sans-serif";
  color: ${(props) => props.color || "white"};
  width: 68px;
  right: 42px;
  top: 52px;
  ${(props) => (props.BuffBaronTimer ? props.BuffBaronTimer : "")};
`;

export const StyleBaronBuffContainer = styled.div`
  position: absolute;
  width: 156px;
  overflow: hidden;
  top: 32px;
  right: 36px;
  height: ${(props) => (props.isGoldDiffActive ? "72px" : "52px")};
  background-color: ${(props) => props.color};
  z-index: -1;
`;

export const BuffElderTimer = styled.div`
  position: absolute;
  height: 35px;
  font-size: 28px;
  font-weight: 500;
  font-family: "futura-pt", "sans-serif";
  color: ${(props) => props.color || "white"};
  width: 68px;
  left: 5px;
  top: 0;
  /* transform: translate(1632px,52px); => position of elder buff when baron buf doesn't have sponsors*/
  transform: translate(1620px, 52px);
  ${(props) => (props.BuffElderTimer ? props.BuffElderTimer : "")}
`;

export const NexusBlueLeftContainer = styled.div`
  position: absolute;
  height: 35px;
  font-size: 31px;
  font-weight: 500;
  color: #141720;
  width: 68px;
  left: 0;
  top: 0;
  text-align: center;
  transform: translate(50px, 732px);
  ${(props) => props.cssNexusLeft || ""}
`;

export const NexusBlueRightContainer = styled.div`
  position: absolute;
  height: 35px;
  font-size: 31px;
  font-weight: 500;
  color: #141720;
  width: 68px;
  left: 0;
  top: 0;
  text-align: center;
  transform: translate(50px, 768px);
  ${(props) => props.cssNexusLeft || ""}
`;

export const NexusRedLeftContainer = styled.div`
  position: absolute;
  height: 35px;
  font-size: 31px;
  font-weight: 500;
  color: #141720;
  width: 68px;
  left: 0;
  top: 0;
  text-align: center;
  transform: translate(1805px, 732px);
  ${(props) => props.cssNexusRight || ""}
`;

export const NexusRedRightContainer = styled.div`
  position: absolute;
  height: 35px;
  font-size: 31px;
  font-weight: 500;
  color: #141720;
  width: 68px;
  left: 0;
  top: 0;
  text-align: center;
  transform: translate(1805px, 768px);
  ${(props) => props.cssNexusRight || ""}
`;

export const InhibitorBlueTopContainer = styled.div`
  position: absolute;
  height: 35px;
  font-size: 31px;
  font-weight: 500;
  color: #141720;
  width: 68px;
  left: 0;
  top: 0;
  text-align: center;
  // transform: translate(50px, 683px);
  transform: translate(50px, 614px);
  ${(props) => props.cssInhibitorLeft || ""}
`;

export const InhibitorBlueMidContainer = styled.div`
  position: absolute;
  height: 35px;
  font-size: 31px;
  font-weight: 500;
  color: #141720;
  width: 68px;
  left: 0;
  top: 0;
  text-align: center;
  // transform: translate(50px, 720px);
  transform: translate(50px, 650px);
  ${(props) => props.cssInhibitorLeft || ""}
`;

export const InhibitorBlueBotContainer = styled.div`
  position: absolute;
  height: 35px;
  font-size: 31px;
  font-weight: 500;
  color: #141720;
  width: 68px;
  left: 0;
  top: 0;
  text-align: center;
  // transform: translate(50px, 756px);
  transform: translate(50px, 683px);
  ${(props) => props.cssInhibitorLeft || ""}
`;

export const InhibitorRedTopContainer = styled.div`
  position: absolute;
  height: 35px;
  font-size: 31px;
  font-weight: 500;
  color: #141720;
  width: 68px;
  left: 0;
  top: 0;
  text-align: center;
  // transform: translate(1805px, 683px);
  transform: translate(1805px, 614px);
  ${(props) => props.cssInhibitorRight || ""}
`;

export const InhibitorRedMidContainer = styled.div`
  position: absolute;
  height: 35px;
  font-size: 31px;
  font-weight: 500;
  color: #141720;
  width: 68px;
  left: 0;
  top: 0;
  text-align: center;
  // transform: translate(1805px, 720px);
  transform: translate(1805px, 650px);
  ${(props) => props.cssInhibitorRight || ""}
`;

export const InhibitorRedBotContainer = styled.div`
  position: absolute;
  height: 35px;
  font-size: 31px;
  font-weight: 500;
  color: #141720;
  width: 68px;
  left: 0;
  top: 0;
  text-align: center;
  // transform: translate(1805px, 756px);
  transform: translate(1805px, 683px);
  ${(props) => props.cssInhibitorRight || ""}
`;

export const MiddleTimer = styled.div`
  position: absolute;
  height: 35px;
  font-size: 28px;
  font-weight: 500;
  color: white;
  width: 68px;
  // left: 3px;
  left: -20px;
  top: 11px;
  font-family: "futura-pt", "sans-serif";
  color: #141720;
  transform: translate(275px, 47px);
  ${(props) => (props.MiddleTimer ? props.MiddleTimer : "")}
`;

export const MiddleTimerLive = styled(MiddleTimer)`
  letter-spacing: 1px;
  ${(props) => (props.MiddleTimerLive ? props.MiddleTimerLive : "")}
`;

export const RightTimer = styled.div`
  position: absolute;
  height: 35px;
  font-size: 28px;
  font-weight: 500;
  color: white;
  width: 68px;
  // left: 3px;
  left: 140px;
  // top: 4px;
  top: 11px;
  font-family: "futura-pt", "sans-serif";
  color: #141720;
  transform: translate(275px, 47px);
  ${(props) => (props.RightTimer ? props.RightTimer : "")}
`;

export const RightTimerLive = styled(RightTimer)`
  letter-spacing: 1px;
  ${(props) => (props.RightTimerLive ? props.RightTimerLive : "")}
`;

export const LeftTimer = styled.div`
  position: absolute;
  height: 35px;
  font-size: 28px;
  color: white;
  width: 68px;
  // left: 6px;
  left: -6px;
  // top: 5px;
  top: 11px;
  font-family: futura-pt, "sans-serif";
  color: #141720;
  font-weight: 500;
  transform: translate(102px, 47px);
  ${(props) => (props.LeftTimer ? props.LeftTimer : "")};
`;

export const LeftTimerLive = styled(LeftTimer)`
  letter-spacing: 1px;
  ${(props) => (props.LeftTimerLive ? props.LeftTimerLive : "")};
`;

export const TokenContainer = styled.div`
  position: absolute;
  left: ${(props) => (props.side === "left" ? "1px" : "2px")};
  top: -1px;
  width: 21px;
  height: 21px;
  background-image: url(${(props) => props.token});
  background-position: center;
  background-repeat: no-repeat;
  ${(props) =>
    props.side === "left"
      ? switchProp("pos", {
          4: "transform: translate(776px, 67px);",
          3: "transform: translate(806px, 67px);",
          2: "transform: translate(836px, 67px);",
          1: "transform: translate(866px, 67px);",
        })
      : switchProp("pos", {
          4: "transform: translate(1133px, 67px);",
          3: "transform: translate(1102px, 67px);",
          2: "transform: translate(1072px, 67px);",
          1: "transform: translate(1040px, 67px);",
        })}
`;

export const SoulContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 49px;
  height: 49px;
  background-image: url(${(props) => props.soul});
  background-position: center;
  background-repeat: no-repeat;

  ${(props) =>
    props.side === "left"
      ? "transform: translate(715px, 60px);"
      : "transform: translate(1167px, 60px);"}
`;

export const StyleSponsorsPatchContainer = styled.div`
  height: 100%;
  width: 100%;
  /* padding-left: 15px; */
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyleSponsorsLJLLogo = styled.img`
  // height: 85%;
  height: 64px;
  margin-right: 10px;
  // height: 56px;
  //   margin: auto;
  //   position: absolute;
  //   bottom: 4px !important;
  //   top: 7px !important;
  //   left: 0px !important;
`;

export const StyleSponsorsPatch = styled.div`
  color: #f00020;
  font-size: 28px;
  //   margin: auto 0 auto 65px;
  padding-left: 10px;
  transform: translate(-8px, 2px);
  // transform: translate(0px, 2px);
  ${(props) => (props.StyleSponsorsPatch ? props.StyleSponsorsPatch : "")};
`;
