// REACT
import React, { Component } from "react";
import PropTypes from "prop-types";

import MatchupV2Screen from "../../storybook-built/dist/components/Games/LJL/MatchupV2";

//3RD PARTY
// import styled from 'styled-components';

import { ThemeContext } from "../View/themes";

class MatchupV2 extends Component {
  static propTypes = {
    active: PropTypes.bool,
    // action: PropTypes.string,
    data: PropTypes.any,
    // statusCallback: PropTypes.func,
  };

  // state = {
  //     status: 'closed',
  //     twitterVisible: false,
  //     animation: 'closed',
  //     callbackName: 'matchup',
  // };

  render() {
    const theme = this.context.screens.matchUpV2 || {};
    const team100MainColor = this.props.data.teamLeftMainColor;
    const team200MainColor = this.props.data.teamRightMainColor;
    const team100SubColor = this.props.data.teamLeftSubColor;
    const team200SubColor = this.props.data.teamRightSubColor;
    const team100Roster = this.props.data.teamLeftRoster;
    const team200Roster = this.props.data.teamRightRoster;
    const team100Logo = this.props.data.teamLeftLogo;
    const team200Logo = this.props.data.teamRightLogo;

    return (
      <MatchupV2Screen
        isShowing={this.props.active}
        team100Roster={team100Roster}
        team100MainColor={team100MainColor}
        team100SubColor={team100SubColor}
        team200Roster={team200Roster}
        team200MainColor={team200MainColor}
        team200SubColor={team200SubColor}
        team100Logo={team100Logo}
        team200Logo={team200Logo}
        theme={theme || {}}
        photosAvailable={this.props.data.photosAvailable}
      />
    );
  }
}

MatchupV2.contextType = ThemeContext;

export default MatchupV2;
