// REACT
import React, { Component } from "react";
import PropTypes from "prop-types";
// ME
import {
  StyleTeamLogo,
  StyleHeader,
  StyleHeaderContainer,
  StyleWhoWillWinContent,
  StyleWhoWillWinHeader,
  StyleHashtag,
  StyleTeamLogoContainer,
  StylePickemLogo,
} from "./styles";
import { StyleInfoBoxBody } from "../styles";
import { get } from "lodash";

class InfoBoxWhoWillWin extends Component {
  static propTypes = {
    // theme: PropTypes.object,
    team100Tricode: PropTypes.string,
    team200Tricode: PropTypes.string,
    team100Color: PropTypes.string,
    team100ColorSub: PropTypes.string,
    team200Color: PropTypes.string,
    team200ColorSub: PropTypes.string,
    logos: PropTypes.object,
    votes: PropTypes.object,
    pickemLogo: PropTypes.string,
  };

  state = {
    barSize: 0,
    team100Percent: "",
    team200Percent: "",
  };

  componentDidMount() {
    this.parseVoteData(this.props.votes);
  }

  parseVoteData = (votes) => {
    const votes100 =
      votes[`${this.props.team100Tricode.toUpperCase()}WIN`] || 0;
    const votes200 =
      votes[`${this.props.team200Tricode.toUpperCase()}WIN`] || 0;
    // Same Number of Vote for both team (even if both = 0)
    if (votes100 === votes200) {
      this.setState({
        barSize: 50,
        team100Percent: "50%",
        team200Percent: "50%",
      });
    }
    // team100 has zero vote
    else if (!votes100) {
      this.setState({
        barSize: 100,
        team100Percent: "0%",
        team200Percent: "100%",
      });
    }
    // team200 has zero vote
    else if (!votes200) {
      this.setState({
        barSize: 0,
        team100Percent: "100%",
        team200Percent: "0%",
      });
    } else {
      const total = votes100 + votes200;
      const barSize = (votes200 / total) * 100;
      const team1PercentCalc = Math.round((votes100 / total) * 100);
      const team100Percent = `${team1PercentCalc}%`;
      const team200Percent = `${100 - team1PercentCalc}%`;

      this.setState({ barSize, team100Percent, team200Percent });
    }
  };

  render() {
    return (
      <div>
        <div
          className="game-screen__info-box__title"
          style={{ width: "1340px" }}
        >
          <StyleWhoWillWinHeader>
            {/* <StyleHashtag>{`${this.props.team100Tricode}WIN`}</StyleHashtag> */}
            <StyleHashtag>{``}</StyleHashtag>
            <StyleHeaderContainer
              StyleHeaderContainer={get(
                this,
                `props.theme.StyleHeaderContainer`
              )}
            >
              {/* <i className="fab fa-twitter" aria-hidden="true"></i> */}
              <StylePickemLogo src={this.props.pickemLogo} />
              <StyleHeader>WHO WILL WIN?</StyleHeader>
            </StyleHeaderContainer>
            {/* <StyleHashtag>{`${this.props.team200Tricode}WIN`}</StyleHashtag> */}
            <StyleHashtag>{``}</StyleHashtag>
          </StyleWhoWillWinHeader>
        </div>
        <StyleInfoBoxBody>
          <StyleWhoWillWinContent
            StyleWhoWillWinContent={get(
              this,
              `props.theme.StyleWhoWillWinContent`
            )}
          >
            <StyleTeamLogoContainer>
              <StyleTeamLogo src={this.props.logos.team100} />
            </StyleTeamLogoContainer>
            <div className="game-screen__info-box__body__vote-bar">
              <div className="game-screen__info-box__body__vote-bar__team-score game-screen__info-box__body__vote-bar__team-score--left">
                {this.state.team100Percent}
              </div>
              <div className="game-screen__info-box__body__vote-bar__team-score game-screen__info-box__body__vote-bar__team-score--right">
                {this.state.team200Percent}
              </div>
              <div
                className={`game-screen__info-box__body__vote-bar__team-bar game-screen__info-box__body__vote-bar__team-bar--bottom`}
                style={{
                  // background: `linear-gradient(0deg, ${this.props.team100ColorSub} 0%, ${this.props.team100Color} 100%)`,
                  background: `linear-gradient(0deg, #004695 0%, #0078ff 100%)`,
                  width: "100%",
                }}
              />
              {/* <div
                            className={`game-screen__info-box__body__vote-bar__team-bar game-screen__info-box__body__vote-bar__team-bar--top`}
                            style={{ backgroundColor: '#000e07', height: '105%'}}
                        /> */}
              <div
                className={`game-screen__info-box__body__vote-bar__team-bar game-screen__info-box__body__vote-bar__team-bar--top`}
                style={{
                  // transform: `scaleX(${this.state.barSize / 100})`,
                  left: "unset",
                  right: 0,
                  width: `calc(${this.state.barSize}% + 20px)`,
                  clipPath: "polygon(40px 0, 100% 0, 100% 100%, 0% 100%)",
                  // background: `linear-gradient(0deg, ${this.props.team200ColorSub} 0%, ${this.props.team200Color} 100%)`,
                  background: `linear-gradient(0deg, #811d0d 0%, #dd3217 100%)`,
                }}
              />
            </div>
            <StyleTeamLogoContainer>
              <StyleTeamLogo src={this.props.logos.team200} />
            </StyleTeamLogoContainer>
          </StyleWhoWillWinContent>
        </StyleInfoBoxBody>
      </div>
    );
  }
}

export default InfoBoxWhoWillWin;
